import axios, { AxiosRequestConfig } from 'axios';
import { AUTH_TOKEN, getItem } from 'utils/storage';

export function GET<T> (endpoint: string, useToken: boolean) {
  return useApi<T>('GET', endpoint, null, useToken);
};

export function POST<T> (endpoint: string, body: any, useToken: boolean) {
  return useApi<T>('POST', endpoint, body, useToken);
};

export function PATCH<T> (endpoint: string, body: any, useToken: boolean) {
  return useApi<T>('PATCH', endpoint, body, useToken);
};

export function PUT<T> (endpoint: string, body: any, useToken: boolean) {
  return useApi<T>('PUT', endpoint, body, useToken);
};

export function DELETE<T> (endpoint: string, body: any, useToken: boolean) {
  return useApi<T>('DELETE', endpoint, body, useToken);
};

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if ([401, 403].includes(error && error.response && error.response.status)) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return Promise.reject(error);
});

async function useApi<T> (
  method: string,
  endpoint: string,
  body: any,
  useToken: boolean
) {
  const { REACT_APP_API_ENDPOINT } = process.env;
  let params: AxiosRequestConfig | undefined = undefined;

  if (useToken) {
    params = {
      headers: {
        Authorization: `Token ${getItem(AUTH_TOKEN)}`
      }
    };
  }

  if (method === 'GET') {
    return await axios.get<T>(`${REACT_APP_API_ENDPOINT}/${endpoint}`, params);
  } else if (method === 'POST') {
    return await axios.post<T>(
      `${REACT_APP_API_ENDPOINT}/${endpoint}`,
      body,
      params
    );
  } else if (method === 'PATCH') {
    return await axios.patch<T>(
      `${REACT_APP_API_ENDPOINT}/${endpoint}`,
      body,
      params
    );
  } else if (method === 'PUT') {
    return await axios.put<T>(
      `${REACT_APP_API_ENDPOINT}/${endpoint}`,
      body,
      params
    );
  } else if (method === 'DELETE') {
    return await axios.delete<T>(`${REACT_APP_API_ENDPOINT}/${endpoint}`, params);
  } else {
    return Promise.reject('Error');
  }
};
